<header class="app-header">
    <div class="top-navigation">
        <div class="logo-container">
            <img class="logo" src="assets/img/ace.png" alt="ACE"/>
        </div>
        <ng-container *ngIf="contracts">
            <div class="" *ngIf="contracts.length > 0">
                <label for="contract-select" class="sr-only"></label>
                <select appSelect name="contract" id="contract-select" [formControl]="contractForm" [disabled]="true">
                    <option [value]="contract.id" *ngFor="let contract of contracts">
                        {{ contract.readableName }}
                    </option>
                </select>
            </div>
        </ng-container>
        <div class="" *ngIf="me$ && (me$ | async) as me">
            <a
                appButton
                routerLink="/profile/user"
            >
                <div>
                    {{ me.firstName }} {{ me.lastName }}
                </div>
            </a>
        </div>
    </div>
    <nav class="main-navigation">
        <a
            routerLink="{{ menuItem.route }}"
            [attr.data-nav]="menuItem.name"
            routerLinkActive="active-link"
            *ngFor="let menuItem of menuItems"
        >
            <span class="">{{ menuItem.name }}</span>
            <app-badge
                appPulseAnimation
                [value]="badge"
                *ngIf="menuItem.badge | async as badge"
            ></app-badge>
        </a>
    </nav>
</header>