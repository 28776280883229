<div class="app-container">
    <div
        [class.invisible]="(loadingService.isLoading$ | async) === false"
        class="fixed top-0 left-0 right-0 z-100 flex pointer-events-none transition-opacity justify-start w-full ml-4 xl:ml-0 xl:justify-center"
    >
        <div
            class="border-l border-r border-b border-seafoam text-seafoam bg-white rounded-b-md px-4 py-2 shadow-unselected"
        >
            <app-loading
                [small]="true"
                [hideMessageOnSmallVW]="true"
            ></app-loading>
        </div>
    </div>
    <app-header
        *ngIf="menuItems$ | async as menu"
        [me$]="userService.me$"
        [menuItems]="menu"
    ></app-header>

    <div class="flex justify-center relative">
        <div class="w-full relative">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<div
    class="refresh-app text-white"
    *ngIf="refreshingPageTime$ | async as refreshingPageTime"
>
    Pagina gaat herladen, in:
    {{ refreshingPageTime | timeLeft : false | async | lowercase }}.
</div>
