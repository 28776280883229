import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { ButtonDirective } from '@app-directives/ui/button/button.directive';
import { SelectDirective } from '@app-directives/ui/inputs/select.directive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutNewComponent } from '@app-components/layout-new/layout-new.component';
// import Bugsnag from '@bugsnag/js';
// import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { NgxMaskModule } from 'ngx-mask';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { EnvironmentService } from '@app-services/environment/environment.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SelectContractComponent } from './components/select-contract/select-contract.component';
import { GraphQLModule } from './modules/graphql/graphql.module';
import { SharedModule } from './modules/shared/shared.module';
import { CruksCodeEditModalComponent } from './components/cruks-code-edit-modal/cruks-code-edit-modal.component';
import {
    VerificationModalComponent,
} from './components/cruks-code-edit-modal/verification-modal/verification-modal.component';
import { LinkDirective } from './directives/ui/link/link.directive';
import { InputDirective } from './directives/ui/inputs/input.directive';
import { CardDirective } from './directives/ui/card/card.directive';
import { LabelDirective } from './directives/ui/label/label.directive';

// create a factory which will return the Bugsnag error handler if we are running on the server
export function errorHandlerFactory() {
    if (! window.location.origin.includes('localhost')) {
        // return new BugsnagErrorHandler();
    }
    return new ErrorHandler();
}

function initialise(
    environmentService: EnvironmentService,
) {
    return async () => {
        await environmentService.initialise();
        // configure Bugsnag asap
        if (! window.location.origin.includes('localhost')) {
            // Bugsnag.start({ apiKey: environmentService.environment.bugsnag });
        }
    };
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        LayoutNewComponent,
        SelectContractComponent,
        CruksCodeEditModalComponent,
        VerificationModalComponent,
    ],
    imports: [
        BrowserModule,
        SharedModule,
        AppRoutingModule,
        GraphQLModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatDividerModule,
        MatListModule,
        MatDialogModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatCardModule,
        NgxMaskModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'nl',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        MatProgressSpinnerModule,
        MatInputModule,
        MatPaginatorModule,
        SelectDirective,
        ButtonDirective,
        InputDirective,
        CardDirective,
        LinkDirective,
        LabelDirective,
    ],
    providers: [
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig,
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' },
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'en-GB',
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initialise,
            deps: [EnvironmentService],
            multi: true,
        },
        {
            provide: ErrorHandler,
            useFactory: errorHandlerFactory,
        },

    ],
    bootstrap: [AppComponent],
    exports: [
        LayoutNewComponent,
        CardDirective,
        LinkDirective,
    ],
})
export class AppModule {
}
