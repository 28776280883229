<app-layout-auth>
    <ng-container *ngIf="userService.me$ | async as me; else loading">
        <h1>
            Welkom {{ me.firstName }}, selecteer een contract.
        </h1>
        <div class="input-container">
            <label appLabel>Contract</label>
            <select appSelect fullWidth [formControl]="contractForm">
                <option [value]="contract.id" *ngFor="let contract of contracts">
                    {{ contract.readableName }}
                </option>
            </select>
        </div>

        <button (click)="setContract()" appButton color="brand"
            [disabled]="! contractForm.valid">
            Doorgaan
        </button>
    </ng-container>
</app-layout-auth>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>