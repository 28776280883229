import { Directive, HostBinding } from '@angular/core';

@Directive({
    selector: '[appLabel]',
    standalone: true,
})
export class LabelDirective {

    @HostBinding('class')
    public get classes(): string {
        let classes: Array<string> = [];
        classes.push('dir-label');
        return classes.join(' ');
    }

}
