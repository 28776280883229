import { booleanAttribute, Directive, HostBinding, Input } from '@angular/core';

export type ButtonColors = 'white' | 'brand' | 'negative' | undefined;

@Directive({
    selector: '[appButton]',
    standalone: true,
})
export class ButtonDirective {

    @Input({})
    public color: ButtonColors = 'white';

    @Input({ transform: booleanAttribute })
    public iconButton: boolean = false;

    @Input({ transform: booleanAttribute })
    public isDisabled: boolean = false;

    @HostBinding('class')
    public get classes(): string {
        let classes: Array<string> = [];
        classes.push('dir-button');
        classes.push('dir-button__' + this.color);
        if (this.isDisabled) {
            classes.push('dir-button__disabled');
        }
        if (this.iconButton) {
            classes.push('dir-button__icon');
        }
        return classes.join(' ');
    }

}