<div class="simple-modal">
    <h2>{{ data.title }}</h2>
    <p>
        {{ data.message }}
    </p>
    <button appButton
            color="brand"
            mat-dialog-close
    >
        Ok
    </button>
</div>