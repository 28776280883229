import { Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-badge',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
})

export class BadgeComponent {

    @Input({ required: true })
    public value: number | null = null;

    @Input()
    public color: 'brand' | 'grey' | 'negative' = 'grey';

    @HostBinding('class')
    public get classes(): string {
        let classes: Array<string> = [];
        classes.push('badge');
        classes.push('badge__' + this.color);
        if (this.value === 0 || this.value === null) {
            classes.push('badge__hidden');
        }
        return classes.join(' ');
    }

}
