import { booleanAttribute, Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: '[appSelect]',
    standalone: true,
})
export class SelectDirective {

    @Input({ transform: booleanAttribute })
    public isDisabled: boolean = false;

    @Input({ transform: booleanAttribute })
    public fullWidth: boolean = false;

    @HostBinding('class')
    public get classes(): string {
        let classes: Array<string> = [];
        classes.push('dir-select');
        if (this.isDisabled) {
            classes.push('dir-select__disabled');
        }
        if (this.fullWidth) {
            classes.push('dir-select__full-width');
        }
        return classes.join(' ');
    }

}