import { Injectable } from '@angular/core';
import {
    AcceptCheckInMutationService,
    AcceptCheckInMutationVariables,
    CheckInBasicFragment,
    CheckInFragment,
    CheckInQueryService,
    CheckInQueueQueryService,
    CheckInUpdatedSubscriptionService,
    CreateCheckInInput,
    CreateCheckInMutationService, HandleUnattendedCheckInMutationService,
    ManualCheckInMutationService,
    ManualCheckInMutationVariables,
    NewCheckInSubscriptionService,
    OverrideCheckInMutationService,
    OverrideCheckInMutationVariables,
    RejectCheckInMutationService,
    RejectCheckInMutationVariables,
} from '@app-graphql';
import { TokensService } from '@app-services/tokens/tokens.service';
import { ApolloPaginator } from '@app-tools/apollo-paginator';
import { log } from '@app-tools/log';
import { clone } from 'ramda';
import { BehaviorSubject, firstValueFrom, Observable, of, Subject } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    scan,
    shareReplay,
    startWith,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';

// eslint-disable-next-line max-len
export const DescriptionFlagAskingForBsn = 'BSN is nodig voor toegangscontrole.';

@Injectable({
    providedIn: 'root',
})
export class CheckInService {
    public selectedCheckIn$ = new BehaviorSubject<CheckInFragment | null>(null);
    public queue: Observable<Array<CheckInBasicFragment>>;
    public currentQueue = new BehaviorSubject<Array<CheckInBasicFragment> | undefined>(undefined);
    public queueCount: Observable<number>;

    private checkInHistoryPaginator = new BehaviorSubject<ApolloPaginator<'historyFirst', 'historyPage'>>(
        new ApolloPaginator('historyFirst', 'historyPage', 25),
    );
    private onRefresh = new Subject<void>();

    constructor(
        private checkInQueueService: CheckInQueueQueryService,
        private acceptCheckInService: AcceptCheckInMutationService,
        private rejectCheckInService: RejectCheckInMutationService,
        private newCheckInService: NewCheckInSubscriptionService,
        private checkInUpdatedService: CheckInUpdatedSubscriptionService,
        private checkInQueryService: CheckInQueryService,
        private tokensService: TokensService,
        private overrideCheckInsService: OverrideCheckInMutationService,
        private readonly handleUnattendedCheckInMutationService: HandleUnattendedCheckInMutationService,
        private readonly manualCheckInMutationService: ManualCheckInMutationService,
        private readonly createCheckInService: CreateCheckInMutationService,
    ) {
        const entranceId$ = this.tokensService.currentEntranceId$.pipe(
            distinctUntilChanged(),
            filter((eId): eId is string => !! eId),
        );

        this.tokensService.currentContractId$.subscribe(() => {
            this.selectCheckIn(undefined);
        });

        const getInitialQueue = (entranceId: string) => this.checkInQueueService.watch({
            entranceId,
        }, {
            errorPolicy: 'none',
        }).valueChanges.pipe(
            catchError(() => of(undefined)),
            map((result) => (result?.data?.checkInQueue ?? []) as CheckInBasicFragment[]),
            map((checkInQueue) => this.sortVisitorQueueByLastCheckIn(checkInQueue)),
        );

        // The cache will handle the updating of existing query values
        const accumulateUpdates = (entranceId: string) => this.checkInUpdatedService.subscribe({
            entranceId,
        }).pipe(startWith(undefined));
        const accumulateNew = (entranceId: string) => this.newCheckInService.subscribe({
            entranceId,
        }).pipe(
            tap(log),
            map((result) => result.data?.newCheckIn as CheckInBasicFragment),
            scan((acc, val) => [...acc, val], [] as CheckInBasicFragment[]),
            startWith([]),
        );
        const currentQueue = entranceId$.pipe(
            tap((entranceId) => {
                accumulateNew(entranceId).subscribe();
                accumulateUpdates(entranceId).subscribe();
            }),
            switchMap((entranceId) => getInitialQueue(entranceId)),
            map((queue) => queue.filter((q) => ! q.handledAt)),
        );

        this.queue = currentQueue.pipe(
            tap((checkIns) => {
                const selected = this.selectedCheckIn$.getValue();
                if (! selected && checkIns[0]) {
                    this.selectCheckIn(checkIns[0].id);
                    return;
                }
                const checkIn = checkIns.find((c) => c.id === selected?.id);
                if (! checkIn && checkIns) {
                    this.selectCheckIn(checkIns[0]?.id ?? undefined);
                }
            }),
            tap(log('test Queue')),
            startWith([] as Array<CheckInBasicFragment>),
            shareReplay(1),
        );

        this.queueCount = this.queue.pipe(
            map((queue) => queue.length),
            startWith(0),
        );
    }

    public selectCheckIn(id?: string, force = false) {
        if (this.selectedCheckIn$.getValue()?.id === id && ! force) {
            return of(this.selectedCheckIn$.getValue());
        }
        if (! id) {
            this.onRefresh.next();
            this.selectedCheckIn$.next(null);
            return;
        }
        const ob = this.checkInQueryService.watch({
            id,
            ...this.checkInHistoryPaginator.getValue().getVariables(),
        }).valueChanges.pipe(
            takeUntil(this.onRefresh),
            map((result) => result.data.checkIn),
        );
        ob.subscribe((checkIn) => {
            this.selectedCheckIn$.next(checkIn ?? null);
        });
        return ob;
    }

    public acceptCheckIn(input: AcceptCheckInMutationVariables) {
        return this.acceptCheckInService.mutate(input);
    }

    public rejectCheckIn(input: RejectCheckInMutationVariables) {
        return this.rejectCheckInService.mutate(input);
    }

    public overrideCheckIn(input: OverrideCheckInMutationVariables) {
        return this.overrideCheckInsService.mutate(input);
    }

    private sortVisitorQueueByLastCheckIn(checkInQueue: CheckInBasicFragment[]) {
        const newCheckInQueue = clone(checkInQueue);
        return newCheckInQueue.sort((checkA, checkB) => {
            const createdAtA = checkA.createdAt ? new Date(checkA.createdAt) : undefined;
            const createdAtB = checkA.createdAt ? new Date(checkB.createdAt) : undefined;
            if (! createdAtA || ! createdAtB) return ! createdAtA ? 1 : -1;
            return createdAtB?.getTime() - createdAtA?.getTime();
        });
    }

    public manualCheckIn(input: ManualCheckInMutationVariables) {
        return this.manualCheckInMutationService.mutate(input);
    }

    public handleUnattendedCheckIn(id: string) {
        return this.handleUnattendedCheckInMutationService.mutate({
            id,
        });
    }

    public async createCheckIn(input: CreateCheckInInput) {
        return firstValueFrom(
            this.createCheckInService.mutate({ input }, {
                errorPolicy: 'all',
            }),
        );
    }
}
